<template>
	<S2SForm title="Client Bags">
		<S2SCard row wrap align-center class="mb-2">
			<v-layout row wrap align-center>
				<v-flex xs6>
					<v-autocomplete
						v-model="model"
						:items="allClients"
						:loading="status.loading"
						no-data-text="No Clients Found"
						hide-no-data
						item-text="description"
						label="Select Client"
						placeholder="Start typing to Search"
						return-object
					></v-autocomplete>
				</v-flex>
			</v-layout>
		</S2SCard>

		<S2SCard v-if="model.id && editMode">
			<S2SFormGenerator ref="form" :schema="safeClientSchema" :data="model" :apiLookup="lookupsApi"></S2SFormGenerator>
			<v-flex xs12 md6 class="text-xs-right" v-show="model.id">
				<v-btn :block="$vuetify.breakpoint.xsOnly" v-show="model.id && editMode" color="accent" @click="updateClient(model)">Apply Changes</v-btn>
			</v-flex>
		</S2SCard>

		<v-data-table
			v-show="model.id && !editMode"
			:headers="bagHeaders"
			:items="bags"
			class="elevation-1"
			:loading="bagsStatus.loading"
			:options.sync="pagination"
			@click:row="onBagClick($event.id)"
		>
			<template v-slot:item.amount="{ item }">{{ formatCurrency(item.amount) }}</template>
			<template v-slot:item.removalamount="{ item }">{{ formatCurrency(item.removalamount) }}</template>
			<template v-slot:item.lastevent="{ item }">{{ formatDatetime(item.lastevent) }}</template>
			<template v-slot:item.removaldate="{ item }">{{ formatDatetime(item.removaldate) }}</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";

import { Client, Bag } from "@/store/modules/safes";
import safeClientSchema from "../../static/config/forms/safe-create-form.json";

import { Status } from "@/store/utils";
import { Utils } from "@/utils";

import { LookupsApi } from "@/api/lookups-api";

export default Vue.extend({
	name: "SearchClient",

	data: function() {
		return {
			id: 0,
			model: {},
			editMode: false,
			pagination: {
				descending: true,
				itemsPerPage: 10,
				sort: "lastevent"
			},

			safeClientSchema: safeClientSchema,
			lookupsApi: LookupsApi.fetchLookups,
			bagHeaders: [
				{ text: "Bag Serial", value: "bagserialnumber" },
				{ text: "Amount", value: "amount", type: "currency", width: "250" },
				{ text: "Active", value: "active" },
				{ text: "Last Event", value: "lastevent", type: "dateTime" },
				{ text: "Vendor Name", value: "vendordescription" },
				{ text: "Removal Amount", value: "removalamount", type: "currency" },
				{ text: "Removal Date", value: "removaldate", type: "dateTime" }
			]
		};
	},

	computed: {
		allClients: function() {
			return this.$store.state.safes.allClients;
		},
		bags: function() {
			return this.$store.state.safes.bags;
		},
		bagsStatus: function() {
			return this.$store.state.safes.bagsStatus;
		},
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	watch: {
		allClients: function() {
			if (!this.id) return;
			for (let client of this.allClients) {
				if (this.id == client.id) {
					this.model = client;
					return;
				}
			}
		},
		model: function(val) {
			this.$store.dispatch("safes/fetchBagsByClientId", val.id);
			this.$router.push({ name: "safes-client-selected", params: { id: "" + val.id } });
		}
	},

	mounted: function() {
		this.$store.dispatch("safes/fetchClients");
	},

	methods: {
		onBagClick(bagid) {
			this.$router.push({ name: "bag-events", params: { bagid } });
		},
		formatCurrency(value) {
			return Utils.formatText(value, Utils.TextType.CURRENCY);
		},
		formatDatetime(value) {
			return Utils.formatText(value, Utils.TextType.DATE_TIME);
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
