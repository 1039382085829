var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":"Client Bags"}},[_c('S2SCard',{staticClass:"mb-2",attrs:{"row":"","wrap":"","align-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-autocomplete',{attrs:{"items":_vm.allClients,"loading":_vm.status.loading,"no-data-text":"No Clients Found","hide-no-data":"","item-text":"description","label":"Select Client","placeholder":"Start typing to Search","return-object":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)],1),(_vm.model.id && _vm.editMode)?_c('S2SCard',[_c('S2SFormGenerator',{ref:"form",attrs:{"schema":_vm.safeClientSchema,"data":_vm.model,"apiLookup":_vm.lookupsApi}}),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.id),expression:"model.id"}],staticClass:"text-xs-right",attrs:{"xs12":"","md6":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.id && _vm.editMode),expression:"model.id && editMode"}],attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"color":"accent"},on:{"click":function($event){return _vm.updateClient(_vm.model)}}},[_vm._v("Apply Changes")])],1)],1):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.id && !_vm.editMode),expression:"model.id && !editMode"}],staticClass:"elevation-1",attrs:{"headers":_vm.bagHeaders,"items":_vm.bags,"loading":_vm.bagsStatus.loading,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event},"click:row":function($event){return _vm.onBagClick($event.id)}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatCurrency(item.amount)))]}},{key:"item.removalamount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatCurrency(item.removalamount)))]}},{key:"item.lastevent",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDatetime(item.lastevent)))]}},{key:"item.removaldate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDatetime(item.removaldate)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }